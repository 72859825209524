



    <div class="">

      <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
          <li type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></li>
          <li type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></li>
          <li type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></li>
        </ol>

        <div class="carousel-inner" >
          <div class="carousel-item active" >
    <img src="https://angprod10.uksouth.cloudapp.azure.com:6005/banners/Banner1.jpg" class="img-fluid mx-auto" >
            <div class="container">
          
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://angprod10.uksouth.cloudapp.azure.com:6005/banners/Banner2.jpg"  class="img-fluid mx-auto">
            <div class="container">
             
            </div>
          </div>
          <div class="carousel-item">
     
     <img src="https://angprod10.uksouth.cloudapp.azure.com:6005/banners/Banner3.jpg"  class="img-fluid mx-auto">
            <div class="container">
             
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel"  data-bs-slide="prev">
          <span class="carousel-control-prev-icon"  aria-hidden="true"></span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>



      <!-- Wrap the rest of the page in another container to center all the content. -->

      <div class="container marketing">

        <!-- Three columns of text below the carousel -->
        <div class="row">
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" src="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/Promotion1.png"  width="140" height="140">

            <h2>3 Weekly Impulse Promotion</h2>
            <p>The regular 3 weekly Deals brochure is packed full of products from leading Brands. All products and deals are carefully selected throughout the year to offer you the best possible value for money and opportunities with seasonal variations when appropriate.</p>
            <a href="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/Heading1.pdf">
            <app-animated-button  mat-raised-button  aria-label=""  [icon]="'keyboard_double_arrow_right'"
            [alignRight]="true" [content]="'View details'"></app-animated-button>
            </a>
          </div><!-- /.col-lg-4 -->
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" src="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/promotion2.png"  width="140" height="140">


            <h2>3 Weekly Grocery Promotion</h2>
            <p>The regular 3 weekly Deals brochure is packed full of products from leading Grocery Brands. All products and deals are carefully selected throughout the year to offer you the best possible value for money and opportunities with seasonal variations when appropriate.</p>
            <a href="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/Heading2.pdf">
            <app-animated-button  mat-raised-button  aria-label=""  [icon]="'keyboard_double_arrow_right'"
            [alignRight]="true" [content]="'View details'"></app-animated-button>
            </a>
          </div><!-- /.col-lg-4 -->
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" src="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/promotion3.png"  width="140" height="140">


            <h2>Clearance and WIGIG promotion</h2>
            <p>The Clearance and WIGIG Deals brochure is packed full of products from leading Brands. All products and deals are carefully selected throughout on An Ad Hoc basis to provide maximum benefit of clearance deals throughout the Year </p>
            <a href="https://angprod10.uksouth.cloudapp.azure.com:6005/promotions/Heading3.pdf">
            <app-animated-button  mat-raised-button  aria-label=""  [icon]="'keyboard_double_arrow_right'"
            [alignRight]="true" [content]="'View details'"></app-animated-button>
            </a>
          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->


        <hr class="featurette-divider">
        



      
    </div>
